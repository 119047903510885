<template>
  <v-navigation-drawer
    v-if="hasDocumentForCurrentExercise"
    right
    app
    clipped
    floating
    permanent
    :temporary="fullPage && !miniDrawer"
    :mini-variant.sync="miniDrawer"
    :width="drawerWidth"
  >
    <v-btn
      v-if="miniDrawer"
      icon
      @click.stop="miniDrawer = !miniDrawer"
      class="ma-2"
    >
      <v-badge
        :content="currentExerciseDocuments.length"
        color="red"
        overlap
      >
        <v-icon>attachment</v-icon>
      </v-badge>
    </v-btn>

    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title
          class="title"
          v-if="!miniDrawer"
        >
          <v-btn
            icon
            @click.stop="miniDrawer = !miniDrawer"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          Documents
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn
          v-if="!miniDrawer"
          icon
          @click.stop="toggleFullPage"
        >
          <v-icon>open_with</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <div
      v-show="!miniDrawer"
      id="documents_container"
      :class="hasSeveralDocumentsForCurrentExercise ? 'with_tabs' : 'without_tabs'"
    >
      <v-tabs
        v-model="tab"
        v-show="hasSeveralDocumentsForCurrentExercise"
      >
        <v-tab
          v-for="document in currentExerciseDocuments"
          :key="document.id"
        >
          {{ document.filename }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          class="exam_document_tab"
          v-for="document in currentExerciseDocuments"
          :key="document.id"
        >
          <quizzes-documents-student-viewer
            v-if="instance"
            :document="document"
            :instance="instance"
            :key="document.id"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-navigation-drawer>
</template>

<script>
import quizzesDocumentsMixin from "./documents/quizzes_documents_mixin"
import QuizzesDocumentsStudentViewer from "./documents/quizzes_documents_student_viewer.vue"
import Vue from "vue"
import rightDrawerMixin from "../shared/rightDrawerMixin"

export default {
  name: "QuizStudentDocumentsDrawer",
  mixins: [quizzesDocumentsMixin, rightDrawerMixin],
  components: { QuizzesDocumentsStudentViewer },
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    currentExercise: {
      type: Object,
      required: true,
    },
    instance: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tab: 0,
    miniDrawer: true,
    screenWidth: 0,
    fullPage: false,
  }),
  computed: {
    currentExerciseDocuments() {
      return this.documents.filter( d => {
        return this.currentExercise.attributes.document_uuids.includes(d.uuid)
      })
    },
    drawerWidthRatio() {
      return this.fullPage ? 0.95 : 0.5
    },
    hasDocumentForCurrentExercise() {
      return this.currentExerciseDocuments.length > 0
    },
    hasSeveralDocumentsForCurrentExercise() {
      return this.currentExerciseDocuments.length > 1
    },
  },
  methods: {
    toggleFullPage() {
      this.fullPage = !this.fullPage
    },
  },
  watch: {
    currentExerciseDocuments: {
      handler(currentExerciseDocuments) {
        if(currentExerciseDocuments.length > 0) this.miniDrawer = false
        Vue.nextTick(() => { this.tab = 0 })
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
#documents_container.without_tabs{
  height: calc(100% - 52px);
}
 #documents_container.with_tabs{
   height: calc(100% - 100px);
 }

 .vertical {
   transform: rotate(90deg);
 }
</style>

<style>
  #documents_container .v-window {
   height: 100%;
  }
  #documents_container .v-window__container,
  #documents_container .v-window-item {
    height: 100%;
  }

</style>
