import { ref, onMounted, onBeforeUnmount, watch } from "vue"
import { createGlobalState, useBroadcastChannel, useIntervalFn } from "@vueuse/core"
import { v1 as uuidv1 } from "uuid"

const MULTI_TAB_CHECK_INTERVAL = 1000 // 1 second
const SINGLE_TAB_CHECK_INTERVAL = 5000 // 5 seconds
const RESPONSE_TIMEOUT = 1000 // 1 second

export const useMultiTabDetection = createGlobalState((quizId) => {
  const hasMultipleTabs = ref(false)
  let lastResponseTime = 0
  let currentInterval = SINGLE_TAB_CHECK_INTERVAL

  // Generate a unique ID for this tab
  const tabId = uuidv1()

  const { post, data, error } = useBroadcastChannel({
    name: `evalmee-tab-detection-quiz-${quizId}`,
  })

  // Handle incoming messages
  const onMessage = (data) => {
    if (!data || data.quizId !== quizId) return

    // Ignore our own messages
    if (data.tabId === tabId) return

    if (data.type === "tab-opened") {
      hasMultipleTabs.value = true
      // Send back a response to let the other tab know we exist
      post({ type: "tab-response", timestamp: Date.now(), quizId, tabId })
    } else if (data.type === "tab-response") {
      hasMultipleTabs.value = true
      lastResponseTime = Date.now()
    }
  }

  // Watch for incoming messages
  watch(data, onMessage)

  // Watch for multi-tab state changes to adjust check interval
  watch(hasMultipleTabs, (isMultiTab) => {
    currentInterval = isMultiTab ? MULTI_TAB_CHECK_INTERVAL : SINGLE_TAB_CHECK_INTERVAL
    // Restart interval with new timing
    pause()
    resume()
  })

  // Periodically check for other tabs
  const { pause, resume } = useIntervalFn(() => {
    const now = Date.now()
    // If we haven't received a response in RESPONSE_TIMEOUT, assume we're alone
    if ((now - lastResponseTime) > (currentInterval + RESPONSE_TIMEOUT)) {
      hasMultipleTabs.value = false
    }
    post({ type: "tab-opened", timestamp: now, quizId, tabId })
  }, currentInterval)

  onMounted(() => {
    if (quizId) {
      // Send initial message
      post({ type: "tab-opened", timestamp: Date.now(), quizId, tabId })
    }
  })

  onBeforeUnmount(() => {
    pause()
  })

  return {
    hasMultipleTabs,
    error,
  }
})
