<template>
  <div
    class="cheat-modal"
    v-if="currentQuiz && quizzesAttemptSummary"
  >
    <v-overlay
      :value="showWarning"
      :color="message?.overlayColor || 'white'"
      opacity="0.8"
    />
    <v-dialog
      v-model="showWarning"
      persistent
      attach=".cheat-modal"
      hide-overlay
      max-width="500"
    >
      <v-sheet class="pa-5">
        <component
          v-if="message?.component"
          :is="message.component"
          v-bind="message.componentProps"
          :quizzes-attempt-summary="quizzesAttemptSummary"
          :quiz="currentQuiz"
        />
        <div class="headline center">
          <div>
            {{ message?.title }}
          </div>

          <eva-button
            v-if="message?.button"
            primary
            class="mt-6"
            :label="message.button.label"
            @click="message.button.action"
          />
        </div>

        <div
          class="mt-6 center"
          v-if="message?.fallbackButton?.visible"
        >
          <a @click="message.fallbackButton.action">
            {{ message.fallbackButton.label }}
          </a>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { computed, ref, toRefs, watch } from "vue"
import vueI18n from "@/plugins/i18n"
import quizOnboardingScreenShare from "../quiz/onboarding/quizOnboardingScreenShare.vue"
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"
import { useGlobalFullscreen } from "@/composables/useGlobalFullscreen"
import { useMultiTabDetection } from "@/composables/useMultiTabDetection"

export default {
  name: "CheatModal",
  props: {
    currentQuiz: { type: Object, required: true },
    quizzesAttemptSummary: { type: Object, required: true },
  },
  setup(props) {
    const { isFullscreen, enter: enterFullscreen, isSupported: isFullscreenSupported } = useGlobalFullscreen()
    const { currentQuiz, quizzesAttemptSummary } = toRefs(props)
    const shouldBeInFullscreen = computed(() => {
      if(skipFullscreen.value) return false
      if(!currentQuiz.value.require_fullscreen) return false
      return !isFullscreen.value
    })
    const skipFullscreen = ref(false)
    const requestFullscreenAttemptCount = ref(0)

    const requestFullscreen = () => {
      requestFullscreenAttemptCount.value++
      enterFullscreen()
    }

    watch(isFullscreen, (value) => {
      if(value) {
        requestFullscreenAttemptCount.value = 0
      }
    })

    const { hasMultipleTabs } = useMultiTabDetection(currentQuiz.value?.id)

    // Message order is important as only the first message will be displayed
    const messages = computed(() => {
      return [
        {
          title: vueI18n.t("live.overlay.multipleTabsDetected"),
          visible: hasMultipleTabs.value,
          overlayColor: "white",
        },
        {
          component: quizOnboardingScreenShare,
          visible: currentQuiz.value.screen_recording &&
            !quizzesAttemptSummary.value?.screen_recording_disabled &&
            !useStudentEventRegisterer(null, null).metSharingScreenRequirements.value,
          overlayColor: "white",
        },
        {
          title: vueI18n.t("live.overlay.stayInFullscreen.label"),
          visible: shouldBeInFullscreen.value && isFullscreenSupported.value,
          button: {
            label: vueI18n.t("live.overlay.stayInFullscreen.button"),
            action: requestFullscreen,
          },
          fallbackButton: {
            label: vueI18n.t("live.overlay.stayInFullscreen.fallbackButton"),
            action: () => {skipFullscreen.value = true},
            visible: requestFullscreenAttemptCount.value > 3,
          },
          overlayColor: "white",
        },
        {
          title: vueI18n.t("live.overlay.stayFocused"),
          visible: isOutsideOfExam.value && currentQuiz.value.detect_mouse_leave,
          overlayColor: "red",
        },
      ].filter(m => !!m.visible)
    })

    const message = computed(() => {
      return messages.value[0]
    })

    const showWarning = computed(() => {
      return messages.value.length > 0
    })

    const isOutsideOfExam = computed(() => {
      return useStudentEventRegisterer(null, null).isOutsideOfExam().value
    })

    return { showWarning, isFullscreen, message, messages }
  },
}
</script>
